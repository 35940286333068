<template>
  <div></div>
</template>
<script>
export default {
  components:{
    //
  },

  //directives
  //filters

  props: {
    //
  },

  data: () => ({
    //
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  }

}
</script>

<style></style>